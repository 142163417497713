import React from 'react';
import NiceModal from '@ebay/nice-modal-react';
import { PracticeTypes } from '@aider/constants-library';
import { ModalWidths } from '../../models/enums/modals';
import StandardModal from './wrappers/StandardModal';
import LLMAssistantContent from './content/LLMAssistantContent';
import type ReportTemplateStore from '../../stores/reportTemplateStore';
import { PromptType } from '../../models/interfaces/components';

interface LLMAssistantModalProps {
  block: PracticeTypes.ReportBlock;
  reportTemplateStore: ReportTemplateStore;
  useRag?: boolean;
  type: PromptType;
}

const LLMAssistantModal = ({ block, type, reportTemplateStore }: LLMAssistantModalProps) => {
  NiceModal.register('LLMAssistantModal', StandardModal);

  const onOk = () => {
    reportTemplateStore.setEditBlock(block);
    reportTemplateStore.useReportContent();
    NiceModal.hide('LLMAssistantModal');
  };

  const onCancel = () => {
    reportTemplateStore.clearReportContent();
    NiceModal.hide('LLMAssistantModal');
  };

  const afterClose = () => {
    reportTemplateStore.clearConversationHistory();
    reportTemplateStore.clearReportContent();
    NiceModal.remove('LLMAssistantModal');
  };

  const ModalProps = {
    name: 'LLM Assistant Modal',
    className: 'report-assistant-modal',
    width: ModalWidths.CONTAINER,
    content: <LLMAssistantContent {...{ block, onOk, onCancel, type }} />,
    afterClose,
    footer: null
  };

  return NiceModal.show('LLMAssistantModal', ModalProps);
};

export default LLMAssistantModal;
