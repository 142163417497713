import * as React from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { observer } from 'mobx-react';
import { PeriodTypes } from '@aider/aider-period-library';
import Markdown from 'markdown-to-jsx';
import { renderCellAlertLevel } from '../../entities/utils';
import renderVisibility from '../atoms/RenderVisibility';
import { useStore } from '../../stores/Store';

interface InsightSummaryProps {
  insightKey: string;
  insights: any;
  insightTitle: string;
}

const InsightSummary = ({
  insightKey,
  insights,
  insightTitle,
}: InsightSummaryProps) => {
  const { localeStore, timePeriodStore } = useStore();
  const isGSTForecast = insightTitle?.includes('forecast');
  const [formattedParts, setFormattedParts] = React.useState(null);

  React.useEffect(() => {
    if (insights?.headline?.parts) {
      const processedParts = {};
      Object.keys(insights.headline.parts).forEach((part) => {
        if (!insights?.headline?.partFormats?.[part]) {
          processedParts[part] = insights.headline.parts[part];
        } else {
          processedParts[part] = localeStore.formatDate(
            insights.headline.parts[part],
            insights.headline.partFormats[part],
          );
        }
      });
      setFormattedParts(processedParts);
    }
  }, [insights?.headline?.parts, insights?.headline?.partFormats]);

  return (
    <div
      onMouseEnter={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}
      style={{ display: 'grid' }}
      className={`insightDataBlock ${
        insights.severity && renderCellAlertLevel(insights.severity.alertLevel)
      } ${isGSTForecast ? 'GSTForecastAlert' : ''}`}
    >
      <span className='overviewIndicatorIcon'>
        {insights.headline.overviewIcon && (
          <OverlayTrigger
            key='bottom'
            placement='bottom'
            overlay={
              <Tooltip id='tooltip-bottom'>Shown on the Dashboard</Tooltip>
            }
          >
            <img height={25} src={insights.headline.overviewIcon} alt='D' />
          </OverlayTrigger>
        )}
      </span>
      <span className='insightDataText'>
        {formattedParts && insights?.headline?.parts && insights?.headline?.partFormats ? (
          <Markdown>
            {localeStore.translation(
              `insights.${insightKey}.summaries.${insights.id}${timePeriodStore.periodGranularity === PeriodTypes.QUARTERLY ? '-quarterly' : ''}`,
              formattedParts,
            )}
          </Markdown>
        ) : (
          <Markdown>
            {insights.headline.label.replace('\n', '<br />')}
          </Markdown>
        )}
      </span>
      <span className='insightDataPoint'>
        {insights.headline.formattedValue}
      </span>
      <span
        className={`insightDataPoint ${
          insights.severity && renderVisibility(insights.severity.alertLevel)
        }`}
        style={{ fontSize: 20 }}
      >
        {insights.headline.icon && (
          <img
            style={{ marginBottom: 7 }}
            height={25}
            src={insights.headline.icon}
            alt=''
          />
        )}{' '}
        {insights.headline.percentageChange}{' '}
      </span>
    </div>
  );
};

export default observer(InsightSummary);
