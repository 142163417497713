import React from 'react';
import { Form } from 'antd';
import { observer } from 'mobx-react';
import { PracticeTypes } from '@aider/constants-library';
import { convertFromRaw } from 'draft-js';
import { useStore } from '../../../stores/Store';
import AiderAlert from '../../AiderAlert';
import AiderGraph from '../../chart/AiderGraph';

const ChartComponent = ({
  className,
  block,
}: {
  className: string,
  block: PracticeTypes.ReportBlock,
}) => {
  const { localeStore, reportTemplateStore } = useStore();
  const isEditing = reportTemplateStore.editBlock === block.id;
  const filteredInsightData = reportTemplateStore.filteredInsightGraphData;

  return (
    <Form.Item
      className={`${className} ${className}--${isEditing ? 'editing' : 'read-only'} ${className}--${block.type}`}
      name={block.id}
      label={null}
      initialValue={block.content}
    >
      {
        block.content ? (
          <div className='reportInsightChartComponent'>
            {(() => {
              const insightKey = convertFromRaw(block.content).getPlainText();
              const selectedInsight = filteredInsightData.find((insight) => insight.insightKey === insightKey);
              if (selectedInsight) {
                return (
                  <div className='reportInsightChartComponent__child' key={`${insightKey}-chart-container`}>
                    <AiderGraph
                      insightKey={insightKey}
                      graphData={selectedInsight.graph}
                    />
                  </div>
                );
              }
              return (
                <AiderAlert
                  type='warning'
                  title={`${localeStore.translation(`insights.${insightKey}.title`)} graph data not available for selected period`}
                />
              );
            })()}
          </div>
        ) : (
          <AiderAlert
            title='Empty graph block'
          />
        )
      }
    </Form.Item>
  );
};

export default observer(ChartComponent);
