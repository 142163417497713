import { makeAutoObservable } from 'mobx';
import type { RootStore } from './Store';
import { PromptType } from '../models/interfaces/components';

export default class TemplateTextStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  // eslint-disable-next-line class-methods-use-this
  getProfitabilityInsightText(insightKey: string) {
    let trendTemplate = '';
    const insightName = `${insightKey}_insightName`;
    const currentPeriodName = 'profitability_periodName';
    const priorPeriod = 'profitability_priorPeriodName';
    const samePeriodPriorYear = 'profitability_samePeriodPriorYearName';
    const currentPeriod = 'profitability_currentPeriod';

    const financialYear = 'financialYearEndYear';
    const lastFinancialYearEnd = 'priorFinancialYearEndYear';
    const previousFinancialYearEnd = 'twoPriorFinancialYearEndYear';

    const currentVsLastYearPercentage = `${insightKey}_currentVsLastYear_percentage`;
    const currentVsLastYearTrend = `${insightKey}_currentVsLastYear_relation`;

    const currentVsLastPercentage = `${insightKey}_currentVsLast_percentage`;
    const currentVsLastTrend = `${insightKey}_currentVsLast_relation`;

    const yearToDateAmount = `${insightKey}_fyToCurrentPeriod`;

    const yearToDateVsLastYearPercentage = `${insightKey}_fyToCurrentPeriodVsLastFYToCurrentPeriod_percentage`;
    const yearToDateVsLastYearTrend = `${insightKey}_fyToCurrentPeriodVsLastFYToCurrentPeriod_relation`;

    const yearToDateVsPreviousYearPercentage = `${insightKey}_fyToCurrentPeriodVsPreviousFYToCurrentPeriod_percentage`;
    const yearToDateVsPreviousYearTrend = `${insightKey}_fyToCurrentPeriodVsPreviousFYToCurrentPeriod_relation`;

    trendTemplate += `#{${currentPeriodName}} #{${insightName}} is #{${insightKey}}, this is #{${currentVsLastYearPercentage}} `;
    trendTemplate += `#{${currentVsLastYearTrend}} compared to #{${samePeriodPriorYear}} and #{${currentVsLastPercentage}} `;
    trendTemplate += `#{${currentVsLastTrend}} than #{${priorPeriod}}.\n\n`;
    trendTemplate += `#{${insightName}} for #{${financialYear}} financial year to #{${currentPeriod}} is #{${yearToDateAmount}} this is `;
    trendTemplate += `#{${yearToDateVsLastYearPercentage}} #{${yearToDateVsLastYearTrend}} compared to the same period in `;
    trendTemplate += `#{${lastFinancialYearEnd}} and #{${yearToDateVsPreviousYearPercentage}} #{${yearToDateVsPreviousYearTrend}} `;
    trendTemplate += `than #{${previousFinancialYearEnd}}.`;

    return trendTemplate;
  }

  // eslint-disable-next-line class-methods-use-this
  getCashFlowInsightText() {
    let shareTextTemplate = '';

    const firstDayOfMonth = 'cashFlow_firstDayOfPeriod';
    const startOfMonthString = 'cashFlow_periodStartMonth';
    const endDayOfMonth = 'cashFlow_periodEndDate';
    const startingCashPosition = 'closingCashPosition';
    const estimatedNetCashFlow = 'estimatedNetCashFlow';
    const estimatedEndCashPosition = 'estimatedCashPosition';

    shareTextTemplate += `This is a cash position estimate driven by your historical trend and predicted future bank transactions in the #{${startOfMonthString}}.\n`;
    shareTextTemplate += `The starting cash position for #{${firstDayOfMonth}} is #{${startingCashPosition}}.\n\n`;
    shareTextTemplate += `The #{${startOfMonthString}} net cash flow is predicted to be around #{${estimatedNetCashFlow}}.\n`;
    shareTextTemplate += `Therefore the estimated cash position for #{${endDayOfMonth}} is #{${estimatedEndCashPosition}}.\n`;

    return shareTextTemplate;
  }

  // eslint-disable-next-line class-methods-use-this
  getCashFlowActualInsightText() {
    let shareTextTemplate = '';
    const lastMonth = 'cashFlowActual_periodEndMonth';
    const previousMonth = 'cashFlowActual_priorPeriodEndMonth';
    const currentnetcashFlow = 'netCashFlow';
    const lastnetcashFlow = 'netCashFlow_last';
    const currentVsLastCashFlow = 'netCashFlow_currentVsLast_percentage';
    const currentNetCashFlowTrend = 'netCashFlow_currentVsLast_relation';
    const currentVsLastEndCashPosition = 'endCashPosition_currentVsLast_percentage';
    const currentVsLastEndCashPositionTrend = 'endCashPosition_currentVsLast_relation';

    shareTextTemplate += `#{${lastMonth}}'s net cash flow of #{${currentnetcashFlow}} shows a #{${currentVsLastCashFlow}} #{${currentNetCashFlowTrend}} `;
    shareTextTemplate += `in cash compared to #{${previousMonth}}'s net cash flow of #{${lastnetcashFlow}} .\n\n`;

    shareTextTemplate += `The end cash position for #{${lastMonth}}'s and #{${previousMonth}}'s \n`;
    shareTextTemplate += `shows a #{${currentVsLastEndCashPosition}} #{${currentVsLastEndCashPositionTrend}} in cash. \n\n`;

    shareTextTemplate += 'Please let me know if you\'d like to discuss cashflow options and cash flow management further.\n';

    return shareTextTemplate;
  }

  // eslint-disable-next-line class-methods-use-this
  getGSTForecast() {
    let shareTextTemplate = '';

    const gstDueDate = 'salesTax_dueDate';
    const gstPeriod = 'salesTax_periodName';
    const paymentForecast = 'estimatedSalesTax';
    const gstCompletionRate = 'salesTax_completionRate_percentage';

    shareTextTemplate += `The next GST payment is due on #{${gstDueDate}} for the #{${gstPeriod}} period.\n\n`;
    shareTextTemplate += `The GST period recordkeeping is #{${gstCompletionRate}} complete and the forecasted amount is around `;
    shareTextTemplate += `#{${paymentForecast}} due based on the record income and expenses so far.\n\n`;
    shareTextTemplate += `If payment is due, make sure you set enough money aside for #{${gstDueDate}}.\n\n`;

    return shareTextTemplate;
  }

  // eslint-disable-next-line class-methods-use-this
  getIncomeTaxInsightText() {
    let shareTextTemplate = '';

    const currentMonth = 'incomeTax_periodEndMonth';
    const financialYearincomeTax = 'incomeTax';
    const lastYearIncomeTax = 'incomeTax_lastFY';
    const financialVslastYearPercentage = 'incomeTax_fyToCurrentPeriodVsLastFYToCurrentPeriod_percentage';
    const financialVslastIncrDcrTrend = 'incomeTax_fyToCurrentPeriodVsLastFYToCurrentPeriod_relation';
    const financialVslastMoreLessTrend = 'incomeTax_fyToCurrentPeriodVsLastFYToCurrentPeriod_moreOrLess';

    shareTextTemplate += `Looking at the year to date net profit - the income tax forecast up to #{${currentMonth}} is around #{${financialYearincomeTax}}, `;
    shareTextTemplate += `this is a #{${financialVslastYearPercentage}} #{${financialVslastIncrDcrTrend}} compared to #{${lastYearIncomeTax}} for the same period last year.\n\n`;
    shareTextTemplate += `You will need #{${financialVslastYearPercentage}} #{${financialVslastMoreLessTrend}} money than the last year set aside for income tax payable.\n\n`;
    return shareTextTemplate;
  }

  // eslint-disable-next-line class-methods-use-this
  getInvoiceStatus() {
    let shareTextTemplate = '';

    const currentMonth = 'invoice_periodEndMonth';
    const invoiceCount = 'invoiceCount';
    const invoiceAmount = 'invoice';
    const averageInvoiceCount = 'averageInvoiceCount';
    const averageMonthlyInvoiceAmount = 'averageMonthlyInvoiceAmount';
    const percentageDifferenceFormatted = 'invoice_currentVsAverage_percentage';
    const increaseDecrease = 'invoice_currentVsAverage_relation';

    shareTextTemplate += `#{${currentMonth}}'s invoicing shows #{${invoiceCount}} invoices sent with a value of #{${invoiceAmount}}.\n\n`;
    shareTextTemplate += `On average you've typically created #{${averageInvoiceCount}} invoices a month with an average monthly value of #{${averageMonthlyInvoiceAmount}}.\n\n`;
    shareTextTemplate += `So that's a #{${percentageDifferenceFormatted}} #{${increaseDecrease}} in total invoiced income for #{${currentMonth}}.\n\n`;
    shareTextTemplate += 'If you have any amounts that you have not invoiced yet, you may want to invoice them soon.';
    return shareTextTemplate;
  }

  rewritePrompt = 'Rewrite the following in plain English using #{clientCountry} spelling & a casual tone for a performance report. Omit any greetings. Keep any heading formatting from the original text.\n';

  executiveSummaryPrompt = 'Draft a very short bullet-pointed executive summary of my business client\'s financial performance. Focus on any significant change that affects financial viability of #{clientCountry} small business #{clientIndustry}. Write in plain English, use #{clientCountry} spelling. Omit context intro, headline, & follow-up actions.\n';

  actionPointsPrompt = 'Based on the financial performance data supplied, suggest 3 action points that a small company in #{clientIndustry} industry in #{clientCountry} can take to improve their financial situation. Use line breaks & numbered list. Write in plain English, use #{clientCountry} spelling.  Omit context intro, headline, & performance summary.\n';

  getLLmPrompt(promptType: PromptType) {
    switch (promptType) {
      case 'rewrite':
        return this.rewritePrompt;
      case 'summary':
        return this.executiveSummaryPrompt;
      case 'action':
        return this.actionPointsPrompt;
      default:
        return '';
    }
  }
}
