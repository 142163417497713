import React, { useState } from 'react';
import Markdown from 'markdown-to-jsx';
import Search from 'antd/es/input/Search';
import { Dropdown } from 'antd';
import { EditOutlined, SaveOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/Store';
import Notification from '../../../components/Notification';
import ButtonSecondary from '../../../components/customAntD/ButtonSecondary';
import GenAI from '../../../components/icons/GenAI';
import { trackMixpanelEvent } from '../../../lib/mixpanel';
import GenAIWhite from '../../../components/icons/GenAIWhite';
import ContentEditor from './ContentEditor';
import AiderGraph from '../../../components/chart/AiderGraph';

interface InsightProps {
  insight: any;
  index: number;
}

const InsightSummary: React.FC<InsightProps> = ({ insight }) => {
  const rootStore = useStore();
  const { reportDownloadStore } = rootStore;
  const [rewritingInsight, setRewritingInsight] = useState(false);
  const [customPrompt, setCustomPrompt] = useState('');
  const [editingInsight, setEditingInsight] = useState(false);
  const [editedInsight, setEditedInsight] = useState(insight.message);

  const rewriteInsight = (prompt) => {
    setRewritingInsight(true);
    reportDownloadStore
      .rewriteInsight(insight.insightKey, prompt)
      .then(() => {
        Notification({
          type: 'success',
          title: 'Insight rewritten successfully',
          description: 'Your insight have been rewritten.',
        });
      })
      .catch(() => {
        Notification({
          type: 'error',
          title: 'Insight rewrite failed',
          description: 'Could not rewrite the insight, please try again...',
        });
      })
      .finally(() => {
        setRewritingInsight(false);
      });
  };

  const items = [
    {
      key: '1',
      label: (
        <ButtonSecondary
          shape='default'
          onClick={() => rewriteInsight(
            `Simplify & rewrite the following insight in a style that my advisory client, a small business owner in the <<lineOfBusiness>> industry in <<country>> without a good understanding of accounting terminology, can understand. Avoid introductions and postcripst. Don't provide a headline.\n\n Insight:\n ${insight.message}.`
          )}
          mixpanelEvent='Rewrite Insight'
          className='generative-button'
        >
          Rewrite in client's tone
        </ButtonSecondary>
      ),
    },
    {
      key: '2',
      label: (
        <>
          <Search
            value={customPrompt}
            onClick={(e) => e?.stopPropagation()}
            placeholder='Enter your prompt here'
            onSearch={() => {
              trackMixpanelEvent({
                description: 'Rewrite Insight',
                rootStore,
                properties: { customPrompt },
              });
              rewriteInsight(
                `${customPrompt}\n\n Insight:\n ${insight.message}.`
              );
              setCustomPrompt('');
            }}
            onChange={(e) => {
              setCustomPrompt(e.target.value);
            }}
            enterButton={<GenAIWhite />}
            className='generative-button'
          />
        </>
      ),
    },
  ];

  const editInsight = () => {
    setEditedInsight(insight.message);
    setEditingInsight(true);
  };

  const saveInsight = () => {
    setEditingInsight(false);
    reportDownloadStore.setInsightText(insight.insightKey, editedInsight);
  };

  return (
    <>
      <hr />
      <div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <h3 className='report-content-heading3'>{insight.title.trim()}</h3>
          <div
            style={{
              display: 'flex',
              alignItems: 'right',
            }}
          >
            {editingInsight ? (
              <ButtonSecondary
                icon={<SaveOutlined />}
                onClick={saveInsight}
                shape='circle'
                size='large'
                style={{ marginRight: 10 }}
                mixpanelEvent='Save Insight'
              />
            ) : (
              <ButtonSecondary
                disabled={rewritingInsight}
                icon={<EditOutlined />}
                onClick={editInsight}
                shape='circle'
                size='large'
                style={{ marginRight: 10 }}
                mixpanelEvent='Edit Insight'
              />
            )}
            <Dropdown
              disabled={rewritingInsight || editingInsight}
              menu={{ items }}
              placement='bottomLeft'
              arrow
            >
              <ButtonSecondary
                shape='circle'
                size='large'
                icon={<GenAI />}
                loading={rewritingInsight}
              />
            </Dropdown>
          </div>
        </div>
        {!editingInsight && (
          <Markdown>{insight.message}</Markdown>
        )}
        {editingInsight && (
          <ContentEditor
            content={insight.message}
            onChange={(content) => {
              setEditedInsight(content);
            }}
          />
        )}
        <AiderGraph
          insightKey={insight.insightKey}
          graphData={insight.graph}
        />
      </div>
      <div className='page-break' />
    </>
  );
};

export default observer(InsightSummary);
