/* eslint-disable no-case-declarations */
import { ArrowDownOutlined, ArrowUpOutlined, CheckCircleFilled, ExclamationCircleFilled } from '@ant-design/icons';
import React, { ReactNode } from 'react';
import { observer } from 'mobx-react';
import { Tooltip } from 'antd';
import { AlertEnums, DashboardEnums } from '@aider/constants-library';
import { useStore } from '../../stores/Store';
import DashboardDataStatus from './DashboardDataStatus';
import { DraftOutlined, FinalOutlined } from '../icons';
import { InfoRing } from '../../components-v2/atoms/Icons';
import { DashboardCellIconProps } from '../../models/interfaces/components';
import TaxStatus from '../tooltipInners/TaxStatus';

const DashboardCellIcon = (props: DashboardCellIconProps) => {
  const { businessesStore } = useStore();
  const { colType } = props;
  let countryCode: string;
  let gstEnabled: boolean;
  let ToolTip: ReactNode;

  if (colType === DashboardEnums.ColumnTypes.gst) {
    if (businessesStore.businesses.has(props.businessId)) {
      countryCode = businessesStore.businesses.get(props.businessId).countryCode;
      gstEnabled = businessesStore.businessGstEnabled(props.businessId);
    }
    ToolTip = <TaxStatus status={countryCode === 'NZ' && gstEnabled ? 'draft' : props.status} countryCode={countryCode} />;
  }

  switch (colType) {
    case DashboardEnums.ColumnTypes.name:
      const businessDetails = businessesStore.businesses.get(props.businessId);
      const [ospKey] = businessDetails.connectedOsps || [null];
      return <DashboardDataStatus businessId={props.businessId} businessName={props.businessName} businessOsp={ospKey} />;
    case DashboardEnums.ColumnTypes.alert:
      switch (props.alertValue) {
        case AlertEnums.Type.ALERT_RED:
          return <ExclamationCircleFilled />;
        case AlertEnums.Type.ALERT_GREEN:
          return <CheckCircleFilled />;
        case AlertEnums.Type.ALERT_GREY:
        default:
          return null;
      }
    case DashboardEnums.ColumnTypes.trend:
      switch (props.trendDirection) {
        case 'up':
          return <ArrowUpOutlined />;
        case 'down':
          return <ArrowDownOutlined />;
        default:
          return null;
      }
    case DashboardEnums.ColumnTypes.gst:
      switch (props.status) {
        case 'draft':
          return <Tooltip title={ToolTip} placement='left'><DraftOutlined className='dashboard-icon dashboard-icon__draft' /></Tooltip>;
        case 'final':
          return <Tooltip title={ToolTip} placement='left'><FinalOutlined className='dashboard-icon dashboard-icon__final' /></Tooltip>;
        case 'estimate':
          if (countryCode === 'NZ' && gstEnabled) {
            return <Tooltip title={ToolTip} placement='left'><DraftOutlined className='dashboard-icon dashboard-icon__draft' /></Tooltip>;
          }
          return <Tooltip title={ToolTip} placement='left'><InfoRing className='dashboard-icon dashboard-icon__estimate' /></Tooltip>;
        case 'unavailable':
          return <Tooltip title={ToolTip} placement='left'><InfoRing className='dashboard-icon dashboard-icon__estimate' /></Tooltip>;
        default:
          return null;
      }
    default:
      return null;
  }
};

export default observer(DashboardCellIcon);
