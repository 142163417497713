import { ApiConstants } from '@aider/constants-library';
import { makeAutoObservable } from 'mobx';
import * as Sentry from '@sentry/browser';
import { CatchAll } from '@aider/constants-library/dist/types/generic';
import { GET } from '../lib/requests';
import type { RootStore } from './Store';

export class InsightStore {
  rootStore: RootStore;

  insightMap: Map<string, any> = new Map();

  insightGraphs: CatchAll<string> = {};

  get insightData() {
    if (this.insightMap.has(this.rootStore.businessesStore.selectedBusinessId)) {
      return this.insightMap.get(this.rootStore.businessesStore.selectedBusinessId);
    }
    return null;
  }

  getInsight(insightKey: string) {
    if (this.insightMap.has(this.rootStore.businessesStore.selectedBusinessId)) {
      return this.insightMap.get(this.rootStore.businessesStore.selectedBusinessId).find((insight) => insight.insightKey === insightKey);
    }
    return null;
  }

  get insightsLoading() {
    return !this.insightMap.has(this.rootStore.businessesStore.selectedBusinessId);
  }

  setInsightGraph(insightKey: string, graph: string) {
    this.insightGraphs[insightKey] = graph;
  }

  getInsightGraph(insightKey: string) {
    return this.insightGraphs?.[insightKey] || null;
  }

  async getBusinessInsights(businessId: string = this.rootStore.businessesStore.selectedBusinessId) {
    const transaction = Sentry.startTransaction({
      name: 'Load Business Insights',
    });
    const url = `${
      ApiConstants.apiEndpointsBase.insightManager
    }/businesses/${businessId}/insights`;

    GET({
      url,
      rootStore: this.rootStore,
      sentryTransaction: transaction,
      sentrySpanName: 'getBusinessInsights'
    }).then((res) => {
      this.insightMap.set(businessId, res.insights);
      transaction.finish();
      return res.insights;
    });
  }

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this, {
      rootStore: false,
    }, { autoBind: true });
  }
}
