// TODO: Refactor this file to remove all the eslint-disable

/* Import dependencies */
import moment from 'moment';
import * as React from 'react';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { smallLoadingComponentWrapped } from '../../entities/components';
import { rootStore } from '../../stores/Store';

/* Import Types and Enums */
import { MessageConfirmationModalProps } from '../../ts/interfaces/organisms/MessageConfirmationModal';
import Mixpanel from '../../lib/mixpanel';

/**
 * A modal that previews a message to be sent to a client
 * @param {MessageConfirmationModalProps} props
 * @returns Component
 */
export const MessageConfirmationModal = ({
  message,
  subject = undefined,
  users,
  renderLoadingComponent,
  type,
  ...props
}: MessageConfirmationModalProps) => {
  /**
   * Replaces the name placeholder with the client's name
   * @param rawMessage the message with the placeholder present
   * @returns
   */
  const replaceName = (rawMessage: string) => {
    let parsedMessage = rawMessage;
    if (users.length > 1) {
      parsedMessage = parsedMessage.replace('<-client_name->', 'everyone');
    } else if (users[0].name === users[0].email) {
      parsedMessage = parsedMessage.replace('<-client_name->', '');
    } else if (users[0].name) {
      parsedMessage = parsedMessage.replace(
        '<-client_name->',
        users[0].name.split(' ')[0]
      );
    } else {
      parsedMessage = parsedMessage.replace(
        '<-client_name->',
        users[0].displayName.split(' ')[0]
      );
    }
    return parsedMessage;
  };

  /**
   * Removes the section start and end placeholders from the message
   * @param rawMessage the message with the placeholder present
   * @returns
   */
  const replaceSectionIndicators = (rawMessage: string) => {
    let parsedMessage = rawMessage;
    rootStore.actionStore.confirmedChartSelection.forEach((fullKey) => {
      const endToken = `<-end_${fullKey}->`;
      const startToken = `<-${fullKey}->`;
      parsedMessage = parsedMessage.replace(endToken, '').replace(startToken, '');
    });
    return parsedMessage;
  };

  /**
   * Replaces the chart image keys with the identifiers
   * @param rawMessage the message with the placeholder present
   * @returns
   */
  const replaceChartImageKeys = (rawMessage: string) => {
    let parsedMessage = rawMessage;
    rootStore.actionStore.confirmedChartSelectionImageKey.forEach((key) => {
      const insightKey = key;
      parsedMessage = parsedMessage.replace(`{${insightKey}}`, `-insight-${insightKey}{-}`);
    });
    return parsedMessage;
  };

  /**
   * Insert insight chart images in to the message at locations specified by the image keys
   * @param rawMessage the message with the placeholder present
   * @returns
   */
  const insertInsightImages = (rawMessage: string) => {
    const insightMessageArray = rawMessage.split('-insight-');
    const formattedMessage = insightMessageArray.map((insight) => {
      const messageParts = insight.split('{-}');
      let messageContent: string;
      let insightKey: string;
      if (messageParts.length > 1) {
        [insightKey, messageContent] = messageParts;
      } else {
        [messageContent] = messageParts;
      }
      const insightChartSource = rootStore.insightStore.getInsightGraph(insightKey);
      if (insightChartSource && rootStore.actionStore.imageAttached) {
        return (
          <div>
            <img
              id={`${insightKey}-chart-image`}
              className='share-insight-graph-preview'
              src={insightChartSource}
              alt=''
            />
            <p>{messageContent}</p>
          </div>
        );
      }
      return (
        <div>
          <p>{insight}</p>
        </div>
      );
    });
    return formattedMessage;
  };

  /**
   * Formats the message to be sent to the client
   * If the message is not an email, no formatting is required
   * @returns
   */
  const formatMessage = () => {
    let formattedMessage = replaceName(message);
    formattedMessage = replaceSectionIndicators(formattedMessage);
    formattedMessage = replaceChartImageKeys(formattedMessage);
    const completedMessage = insertInsightImages(formattedMessage);
    return completedMessage;
  };

  /** Obtain the formatted message */
  const formattedMessage = formatMessage();

  /** Define the component return */
  return (
    // eslint-disable-next-line max-len
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div
      id='metadataModal'
      className='confirm-ac-modal-container'
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <div className='card--panel metadata-card' style={{ padding: 0 }}>
        <div
          style={{
            color: '#505050',
            fontSize: 24,
            width: '100%',
            fontWeight: 700,
            borderBottom: '1px solid #e1e1e5',
            marginBottom: 20,
            padding: 20,
          }}
        >
          Message Preview
        </div>
        <div
          style={{
            paddingLeft: 20,
            paddingRight: 20,
            color: '#333333',
            fontSize: 14,
            width: '100%',
            fontWeight: 400,
            paddingBottom: 10,
          }}
        >
          <>
            <div style={{ display: 'inline-block', width: 95 }}>
              <span style={{ color: '#333333' }}>Subject: </span>
            </div>
            <span>{subject}</span>
          </>
        </div>
        <div
          style={{
            color: '#333333',
            fontSize: 14,
            width: '100%',
            fontWeight: 400,
            paddingBottom: 5,
            paddingLeft: 20,
            paddingRight: 20,
          }}
        >
          <div style={{ display: 'inline-block', width: 95 }}>
            <span style={{ color: '#333333' }}>Reply To: </span>
          </div>
          <span>{rootStore.authStore.practiceUser.user.email}</span>
        </div>
        <div
          style={{
            color: '#333333',
            fontSize: 14,
            width: '100%',
            fontWeight: 400,
            paddingTop: 5,
            overflowX: 'auto',
            paddingLeft: 20,
            paddingRight: 20,
            height: 40,
          }}
        >
          <div
            style={{
              display: 'inline-block',
              width: 95,
            }}
          >
            <span style={{ color: '#333333' }}>Recipients:</span>
          </div>
          <div
            className='scrollbar sbx'
            style={{
              display: 'inline-flex',
              overflowX: 'auto',
              maxWidth: 460,
              whiteSpace: 'pre',
            }}
          >
            {users.map((user, ix, arr) => {
              if (ix <= 3) {
                if (user.name === user.email) {
                  return `< ${user.email} >, `;
                }
                return `${user.name || user.displayName}, `;
              } if (ix === 4) {
                return `...${arr.length} others`;
              }
              return '';
            })}
          </div>
        </div>{' '}
        <div style={{ width: '100%', borderTop: '1px solid #e1e1e5' }} />
        <div
          style={{
            marginTop: 10,
            justifyContent: 'center',
            paddingLeft: 20,
            paddingRight: 20,
            paddingBottom: 'inherit',
          }}
        >
          <>
            <div
              className='scrollbar'
              style={{
                width: '100%',
                maxHeight: 340,
                minHeight: 340,
              }}
            >
              <div
                className='metadata'
                style={{ color: '#8e8e8e', whiteSpace: 'pre-wrap' }}
              >
                {formattedMessage}
              </div>
            </div>
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                padding: '0.5em 0',
              }}
            >
              { /* eslint-disable-next-line max-len  */}
              { /* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */ }
              <div
                onClick={() => {
                  rootStore.actionStore.closeConfirmedMessageModalButKeepRecipientInfo();
                  props.renderActionModal(true);
                }}
                className='actionButtonNew abn-back abn-medium'
                style={{ display: 'inline-block', textAlign: 'left', marginLeft: 15 }}
              >
                <div className='abn-backArrowHolder'>
                  <LeftOutlined />
                </div>
                Edit
              </div>

              { /* eslint-disable-next-line max-len  */}
              { /* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */ }
              <div
                id='sendAC'
                style={{ float: 'right' }}
                className={`actionButtonNew abn-small ${
                  !renderLoadingComponent ? '' : 'loadingButtonBackground'
                }`}
                // eslint-disable-next-line consistent-return
                onClick={() => {
                  Mixpanel.track('Send Email', {
                    businessId: rootStore.businessesStore.selectedBusinessId,
                    businessName: rootStore.businessesStore.selectedBusiness.name,
                    recipients: users,
                  });
                  if (!renderLoadingComponent) {
                    if (type !== 'instant') {
                      // eslint-disable-next-line @typescript-eslint/no-unused-vars
                      let fortWeeks: number[];
                      // eslint-disable-next-line radix
                      if (parseInt(moment().format('w')) % 2 === 0) {
                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                        fortWeeks = [...Array(52).keys()].filter(
                          (day) => day % 2 === 0
                        );
                      } else {
                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                        fortWeeks = [...Array(52).keys()].filter(
                          (day) => day % 2 === 1
                        );
                      }
                    } else {
                      rootStore.actionStore.setConfirmedMessageSubjectNull();
                      rootStore.actionStore.closeConfirmedMessageModal();
                      return rootStore.actionStore
                        .sendEmailAction(
                          users,
                          message, // Important to send unformatted message here
                          type,
                          subject
                        )
                        .catch((err) => console.error(err));
                    }
                  }
                }}
              >
                {!renderLoadingComponent ? (
                  <>
                    <div>Send now</div>
                    <div className='bah-small'>
                      <RightOutlined />
                    </div>
                  </>
                ) : (
                  smallLoadingComponentWrapped
                )}
              </div>
            </div>
          </>
        </div>
      </div>
    </div>
  );
};
