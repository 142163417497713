/**
 * A map that converts the values recieved from
 * the Insight Service to sensical values for the
 * front end.
 */
export enum Urgency {
  danger = 'RED',
  warning = 'YELLOW',
  success = 'GREEN',
  muted = 'GREY',
}

export enum FeatureFlags {
  impersonateUser = 'impersonateUser',
  advisoryAssistant = 'AdvisoryAssistant',
  clientTransactionSummary = 'clientTransactionSummary',
  ManagePrompts = 'ManagePrompts',
}

export enum InsightType {
  OVERVIEW = 'Overview',
  DATAHEALTH = 'DataHealth',
  PROFITABILITY = 'Profitability',
  REPORT = 'Report',
  ADVISORY_AI = 'Advisory',
}

export const SelectablePeriods = [
  InsightType.PROFITABILITY,
];

export const PeriodInsights = [
  'reconciliation',
  'incomeTax'
];

export const PeriodSelectionHeaderTitles = {
  reconciliation: 'GST Period Completion for',
  incomeTax: 'Income Tax - FY to'
};

export enum PeriodGranularities {
  monthly = 'Monthly',
  quarterly = 'Quarterly',
}

export enum CookieKeys {
  appConnect = 'appConnect',
  appReferral = 'appReferral',
  appDisconnect = 'appDisconnect',
  prevDestination = 'prevDestination',
  userHasLoggedIn = 'userHasLoggedIn',
  inviteAdvisors = 'inviteAdvisors',
  unclaimedConnection = 'unclaimedConnection',
  inviteToken = 'inviteToken',
  prevQueryString = 'prevQueryString',
  redirect = 'redirect',
  initialConnection = 'initialConnection',
  connectionId = 'connectionId',
  loggingIn = 'loggingIn',
  sessionTimedOut = 'sessionTimedOut',
}
