import { observer } from 'mobx-react';
import React from 'react';
import { Dropdown } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import GenAI from '../../icons/GenAI';
import { useStore } from '../../../stores/Store';
import { PromptType } from '../../../models/interfaces/components';

const LLMAssistantHeader = ({ promptType, setPromptType }) => {
  const { localeStore } = useStore();
  return (
    <header className='report-assistant-modal__header'>
      <GenAI /> Report Assistant
      <span>-</span>
      <Dropdown
        className='report-assistant-modal__header--dropdown'
        overlayClassName='aider-dropdown'
        menu={{
          items: [
            {
              key: 'rewrite',
              label: localeStore.translation('report-editor.prompt-types.rewrite'),
            },
            {
              key: 'summary',
              label: localeStore.translation('report-editor.prompt-types.summary'),
            },
            {
              key: 'action',
              label: localeStore.translation('report-editor.prompt-types.action'),
            },
            {
              key: 'custom',
              label: localeStore.translation('report-editor.prompt-types.custom'),
            },
          ],
          selectable: true,
          defaultSelectedKeys: [promptType],
          onClick: ({ key }) => {
            setPromptType(key as PromptType);
          },
        }}
      >
        <span>
          {localeStore.translation(`report-editor.prompt-types.${promptType}`)}
          <DownOutlined />
        </span>
      </Dropdown>
    </header>
  );
};

export default observer(LLMAssistantHeader);
