import React from 'react';
import { Dropdown, MenuProps, Select } from 'antd';
import { observer } from 'mobx-react';
import { ComplianceChecklistEnums } from '@aider/constants-library';
import { ReportTypes } from '@aider/constants-library/dist/enums/docX';
import { DownloadOutlined, FileExcelFilled, FileWordFilled } from '@ant-design/icons';
import { useStore } from '../../stores/Store';
import ButtonSecondary from '../customAntD/ButtonSecondary';
import Notification from '../Notification';
import { trackMixpanelEvent } from '../../lib/mixpanel';

const PeriodSelector = () => {
  const rootStore = useStore();
  const { checklistStore, localeStore, reportDownloadStore } = rootStore;
  const [dccDownloading, setDccDownloading] = React.useState(false);

  const usPractice = rootStore.practiceStore.countryCode.toLowerCase() === 'us';
  const options = [
    {
      value: ComplianceChecklistEnums.Types.yearlyChecklist,
      label: localeStore.translation('period-close.checklist-label', {
        periodType: localeStore.translation(`period-close.period-type.${ComplianceChecklistEnums.Types.yearlyChecklist}`)
      }),
    },
    {
      value: ComplianceChecklistEnums.Types.monthlyChecklist,
      label: localeStore.translation('period-close.checklist-label', {
        periodType: localeStore.translation(`period-close.period-type.${ComplianceChecklistEnums.Types.monthlyChecklist}`)
      }),
    },
  ];

  if (!usPractice) {
    options.splice(1, 0, {
      value: ComplianceChecklistEnums.Types.gstPeriodChecklist,
      label: localeStore.translation('period-close.checklist-label', {
        periodType: localeStore.translation(`period-close.period-type.${ComplianceChecklistEnums.Types.gstPeriodChecklist}`)
      }),
    });
  }

  if (
    checklistStore.activePeriod
    && checklistStore?.currentChecklistGroups?.tableGroups?.findIndex(
      (item) => item.tableGroupPeriod.periodName === checklistStore.activePeriod
    ) === -1
  ) {
    checklistStore.activePeriod = checklistStore.defaultPeriod;
  }

  const onImportButtonClick = async ({ key }) => {
    setDccDownloading(true);
    trackMixpanelEvent({
      description: 'Download Period Close Report',
      properties: { downloadType: key },
      rootStore
    });
    try {
      switch (key) {
        case 'excel':
          await reportDownloadStore.downloadAsExcel();
          break;
        case 'word':
        default:
          await reportDownloadStore.downloadAsDocx(ReportTypes.dataCompliance);
          break;
      }
      Notification({
        type: 'success',
        title: 'Download successful',
        description: 'Report downloaded, please check your downloads folder'
      });
    } catch (err) {
      Notification({
        type: 'error',
        title: 'Download failed',
        description: 'Could not download, please try again',
      });
    } finally {
      setDccDownloading(false);
    }
  };

  const importItems: MenuProps['items'] = [
    {
      key: 'excel',
      label: 'Download as Excel spreadsheet',
      icon: <FileExcelFilled className='periodSelecterHeader__excel-icon' />,
    },
    {
      key: 'word',
      label: 'Download as Word document',
      icon: <FileWordFilled className='periodSelecterHeader__word-icon' />,
    }
  ];

  const importMenu = {
    items: importItems,
    onClick: onImportButtonClick,
  };

  const defaultType = usPractice
    ? ComplianceChecklistEnums.Types.monthlyChecklist
    : ComplianceChecklistEnums.Types.gstPeriodChecklist;

  return (
    <div className='periodSelecterHeader'>
      <div className='periodSelecterHeader__block'>
        <Select
          defaultValue={checklistStore.activeChecklistType || defaultType}
          size='large'
          onChange={(selectedValue) => {
            trackMixpanelEvent({
              description: 'Period Close: Change Granularity',
              properties: { selectedType: selectedValue },
              rootStore
            });
            checklistStore.activeChecklistType = selectedValue;
          }}
          options={options}
        />
        {checklistStore.currentChecklistGroups
          && checklistStore.currentChecklistGroups.tableGroups
          && (checklistStore.activePeriod || checklistStore.defaultPeriod) && (
            <Select
              value={
                checklistStore.activePeriod || checklistStore.defaultPeriod
              }
              size='large'
              onChange={(selectedPeriod) => {
                trackMixpanelEvent({
                  description: 'Period Close: Change Period',
                  properties: { selectedPeriod },
                  rootStore
                });
                checklistStore.activePeriod = selectedPeriod;
              }}
              options={checklistStore.currentChecklistGroups.tableGroups?.map(
                (item) => ({
                  value: item.tableGroupPeriod.periodName,
                  label: item.tableGroupPeriod.periodName,
                })
              )}
            />
        )}
      </div>
      <div className='periodSelecterHeader__block'>
        {(checklistStore.activePeriod || checklistStore.defaultPeriod) && (
          <>
            <Dropdown menu={importMenu}>
              <ButtonSecondary
                loading={dccDownloading}
                disabled={dccDownloading}
                icon={<DownloadOutlined />}
                size='large'
                mixpanelEvent='Download Period Close Report'
                mixpanelProperties={{
                  selectedPeriodType: checklistStore.activeChecklistType,
                  selectedPeriod:
                    checklistStore.activePeriod || checklistStore.defaultPeriod,
                }}
              >
                Download
              </ButtonSecondary>
            </Dropdown>
          </>
        )}
      </div>
    </div>
  );
};

export default observer(PeriodSelector);
