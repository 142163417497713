import { observer } from 'mobx-react';
import { Chart, ChartComponentLike } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import React, { useEffect } from 'react';
import Format from '@aider/aider-formatting-library';
import { PeriodTypes } from '@aider/aider-period-library';
import { useStore } from '../../stores/Store';
import { GraphColors, GraphFont } from '../../models/enums/components';
import { GraphFormats, RGBColorCodes, convertGraphData, formatChartValue, formatChartYAxisValue, generateRGBColor, getBackgroundColor, getBorderColor, getPointBackgroundColor } from '../../lib/componentHelpers/graphHelpers';
import { InsightTab } from '../../stores/v1/pageStore';

// Register the custom annotation plugin with the chart library
Chart.register(annotationPlugin);
Chart.register({
  id: 'custom_canvas_background_color',
  beforeDraw: (chart) => {
    const ctx = chart.canvas.getContext('2d');
    ctx.save();
    ctx.globalCompositeOperation = 'destination-over';
    ctx.fillStyle = 'white';
    ctx.fillRect(0, 0, chart.width, chart.height);
    ctx.restore();
  },
} as ChartComponentLike);

const AiderGraph = ({ insightKey, graphData }) => {
  const { businessesStore, insightStore, localeStore, pageStore, reportTemplateStore, timePeriodStore } = useStore();
  const [chartInstance, setChartInstance] = React.useState(null);
  const [newGraphData, setNewGraphData] = React.useState(null);
  const [datasets, setDatasets] = React.useState(null);
  const [format, setFormat] = React.useState(null);
  const chartRef = React.useRef(null);

  useEffect(() => {
    if (!graphData?.version || graphData?.version === 1) {
      const convertedGraphData = convertGraphData(graphData);
      setNewGraphData(convertedGraphData);
    } else {
      setNewGraphData(graphData);
    }
  }, [graphData]);

  // Harness the animation onComplete event to save the graph as a base64 image
  const animation = {
    onComplete(c) {
      const base64Image = c.chart.toBase64Image();
      insightStore.setInsightGraph(insightKey, base64Image);
    },
  };

  // Generate the x and y axis titles based on the insight key
  const xAxisTitle = localeStore.translation(`insights.${insightKey}.graph.xAxis`, {
    currency: businessesStore.selectedBusiness.currencyCode
  });

  const yAxisTitle = localeStore.translation(`insights.${insightKey}.graph.yAxis`, {
    currency: businessesStore.selectedBusiness.currencyCode,
    currencySymbol: businessesStore.selectedBusiness.currencySymbol
  });

  // When the selected business changes, update the format library
  useEffect(() => {
    setFormat(new Format(businessesStore.selectedBusiness.currencyCode, businessesStore.selectedBusiness.countryCode));
  }, [businessesStore?.selectedBusiness?.currencyCode, businessesStore?.selectedBusiness?.countryCode]);

  // Create the annotations object which draws reference lines / boxes on the graph
  const annotationsObject: any = {
    // This annotation draws a line at y=0 so that negative values are easily visible
    zero: {
      type: 'line',
      yMin: 0,
      yMax: 0,
      borderColor: 'rgba(159,160,172,0.67)',
    }
  };

  if (newGraphData?.annotation) {
    // If the graph has an annotation, add it to the annotations object to display
    // a reference line on the graph with a label. EG: Average as on GST
    annotationsObject.average = {
      type: 'line',
      yMin: newGraphData.annotation.value,
      yMax: newGraphData.annotation.value,
      borderColor: 'rgba(159,160,172,0.67)',
      borderDash: [4, 3],
      label: {
        content: newGraphData.annotation.label,
        enabled: true,
        position: 'end',
        backgroundColor: 'rgba(159,160,172,0.67)',
        color: '#505050',
      },
    };
  }

  React.useEffect(() => {
    if (!newGraphData?.datasets) return;

    // Do any necessary reworking on the graph data to ensure
    // it is in the correct format for the graph library to
    // render the graph correctly
    const graphFormat = GraphFormats[insightKey];
    if (!graphFormat) return;

    // Apply any additional formatting to the datasets based on defined graph format styles
    graphFormat.forEach((fmt, ix: number) => {
      if (!datasets?.[ix]) return;
      switch (fmt?.style) {
        case 'dashed': // Dashed line
          datasets[ix].borderDash = [4, 3];
          if (ix > 0) {
            datasets[ix].pointBackgroundColor = generateRGBColor(RGBColorCodes.white);
          }
          break;
        case 'filled': // Line that has a solid color between it and the origin
          datasets[ix].fill = 'origin';
          break;
        default:
          break;
      }
    });

    const workingDatasets = newGraphData?.datasets?.map((dataset, ix) => {
      const type = graphFormat?.[ix]?.type;
      const { data } = dataset;
      let categoryPercentage: number; // defines how wide the group containing datapoints is
      let barPercentage: number; // Defines how much of the category space the bar takes up
      let borderRadius: number; // Defines the corner radius of the bar
      let datasetType: string = type; // Line / Bar etc
      let pointBackgroundColor: string; // Defines the BG color of line graph points
      let pointBorderColor: string; // Defines the border color of line graph points

      // This increases the size of the bar, used in stacked bar graphs, to have the
      // bars fill the entire space by adding them together.
      let inflateAmount: string;

      // This needs to be a string of the 'ID' of a stack so that it can be stacked together correctly
      const stack: string = dataset?.stack || undefined;

      // Apply default formatting based on the dataset graph type
      if (type === 'bar') {
        borderRadius = 6;
      } else if (type === 'line') {
        pointBackgroundColor = getPointBackgroundColor(insightKey, ix);
        pointBorderColor = getBorderColor(insightKey, ix);
      }

      // Perform any insight Specific formatting
      switch (insightKey) {
        case 'gst':
          categoryPercentage = 0.9;
          barPercentage = 0.28;
          inflateAmount = 'auto';
          break;
        case 'reconciliation':
          categoryPercentage = 0.9;
          barPercentage = 0.28;
          inflateAmount = 'auto';
          break;
        case 'invoiceStatus':
          categoryPercentage = 0.39;
          barPercentage = 0.89;
          datasetType = undefined;
          break;
        default:
          break;
      }

      return ({
        type: datasetType,
        stack,
        label: dataset.label,
        data,
        borderColor: getBorderColor(insightKey, ix),
        backgroundColor: getBackgroundColor(insightKey, ix),
        barPercentage,
        categoryPercentage,
        borderRadius,
        borderWidth: 2,
        inflateAmount,
        pointBackgroundColor,
        pointBorderColor,
      });
    });
    setDatasets(workingDatasets);
  }, [newGraphData?.datasets]);

  React.useEffect(() => {
    if (!datasets || !format) return;

    if (chartInstance) {
      chartInstance.destroy();
    }

    // Create the chart instance
    const chart = new Chart(
      chartRef.current,
      {
        type: 'bar',
        data: {
          datasets,
          labels: newGraphData?.legend
        },
        options: {
          animation,
          responsive: true,
          interaction: {
            mode: ['gst'].indexOf(insightKey) > -1 ? 'point' : 'index',
            axis: 'x',
          },
          plugins: {
            annotation: {
              annotations: annotationsObject,
            },
            legend: {
              position: 'bottom',
              align: 'start',
              fullSize: true,
              labels: {
                boxWidth: 20,
                boxHeight: 20,
                color: GraphColors.textColor,
                font: {
                  size: GraphFont.size,
                },
              },
            },
            tooltip: {
              callbacks: {
                title: (headerInstance) => {
                  const rawLabel = headerInstance[0].label;
                  if (['incomeTax', 'grossProfit', 'directCosts', 'netProfit', 'revenue', 'operationalExpenses'].indexOf(insightKey) > -1
                    && (
                      (
                        pageStore.tabActive !== InsightTab.report
                        && timePeriodStore.periodGranularity === PeriodTypes.MONTHLY
                      ) || (
                        pageStore.tabActive === InsightTab.report
                        && reportTemplateStore.selectedPeriodData?.granularity === PeriodTypes.MONTHLY
                      )
                    )
                  ) {
                    return localeStore.translation(`months.${rawLabel}`);
                  }
                  return rawLabel;
                },
                label: (context) => (
                  `${context.dataset.label}: ${formatChartValue(context.parsed.y, insightKey, format)}`
                ),
              }
            },
          },
          scales: {
            x: {
              title: {
                text: xAxisTitle,
                display: xAxisTitle !== '',
                color: GraphColors.textColor,
                font: {
                  size: GraphFont.size,
                },
              },
              stacked: ['invoiceStatus', 'reconciliation', 'gst'].indexOf(insightKey) > -1 ? true : undefined,
              grid: {
                display: false,
              },
              beginAtZero: false,
            },
            y: {
              stacked: ['invoiceStatus', 'reconciliation'].indexOf(insightKey) > -1 ? true : undefined,
              title: {
                text: yAxisTitle,
                display: yAxisTitle !== '',
                color: GraphColors.textColor,
                font: {
                  size: GraphFont.size,
                },
              },
              ticks: {
                callback(value) {
                  return formatChartYAxisValue(value, insightKey, format);
                },
                color: GraphColors.textColor,
                font: {
                  size: GraphFont.size,
                },
              },
              grid: {
                borderDash: [1, 2],
                color: GraphColors.gridColor,
              },
              beginAtZero: ['cashFlow', 'cashFlowActual'].indexOf(insightKey) > -1 ? true : undefined,
            }
          }
        }
      }
    );
    setChartInstance(chart);
  }, [format, businessesStore.selectedBusiness, insightKey, datasets]);

  return (
    <canvas
      id={`${insightKey}-graph-component`}
      className='aider-graph'
      ref={chartRef}
    />
  );
};

export default observer(AiderGraph);
