import { firestore } from 'firebase';
import { makeAutoObservable, reaction } from 'mobx';
import * as Sentry from '@sentry/browser';
import { v4 as uuid } from 'uuid';
import { PeriodTypes } from '@aider/aider-period-library';
import type { RootStore } from './Store';
import handleError from '../lib/errorHandler';

type Message = {
  timestamp?: Date;
  message: string;
  provider?: string;
} & (UserMessage | AssistantMessage);

type UserMessage = {
  from: 'user';
  includedDocuments: string[];
};

type AssistantMessage = {
  from: 'assistant';
};

export type UserPrompt = {
  id: string;
  page: string;
  label: string;
  text: string;
};

interface IChat {
  id: string;
  businessId: string;
  userId: string;
  timestamp?: Date;
  messages: Message[];
}

export default class AssistantStore {
  rootStore: RootStore;

  messages: Message[] = [];

  messageHistory: Message[] = [];

  chatId: string = null;

  timestamp: Date;

  userPrompts: UserPrompt[] = [];

  page: string = 'insights';

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(
      this,
      {
        rootStore: false,
      },
      { autoBind: true }
    );

    // Trigger reaction to obtain user prompts.
    reaction(
      () => typeof this.rootStore.authenticationStore.accessToken !== 'undefined'
        && typeof this.rootStore.userStore.id !== 'undefined',
      (id) => {
        if (id) {
          try {
            firestore()
              .collection('/advisoryAssistantUserPrompts')
              .doc(this.rootStore.userStore.id)
              .get()
              .then((doc) => {
                const { userPrompts } = doc?.data() || { userPrompts: [] };
                this.userPrompts = userPrompts.map((userPrompt) => {
                  const prompt = { ...userPrompt };
                  prompt.id = prompt.id ?? uuid();
                  prompt.page = prompt.page ?? 'insights';
                  return prompt;
                });
              });
          } catch (error) {
            handleError({
              error,
              status: 'error_retrieving',
              transaction: 'assistantStore - reaction - userPrompts',
              operation: 'get',
            });
          }
        }
      }
    );
  }

  get paginatedPrompts() {
    const dashboardPrompts = this.userPrompts
      .filter((userPrompt) => userPrompt.page === 'dashboard')
      .sort((a, b) => a.label.localeCompare(b.label));

    const insightsPrompts = this.userPrompts
      .filter(
        (userPrompt) => userPrompt.page === 'insights' || !userPrompt?.page
      )
      .sort((a, b) => a.label.localeCompare(b.label));

    const assistantPrompts = this.userPrompts
      .filter((userPrompt) => userPrompt.page === 'assistant')
      .sort((a, b) => a.label.localeCompare(b.label));

    return { dashboardPrompts, insightsPrompts, assistantPrompts };
  }

  get chat(): IChat {
    return {
      id: this.chatId,
      businessId:
        this.page === 'insights'
          ? this.rootStore.businessStore?.selectedBusinessId
          : this.page === 'dashboard'
            ? this.rootStore.practiceStore.id
            : this.page,
      userId: this.rootStore.userStore?.id,
      timestamp: this.timestamp,
      messages: this.messages,
    };
  }

  async addUserPrompt(prompt: UserPrompt) {
    // TODO: if prompt has an id, then it must already exist in the array, so we should update it.
    const promptIndex = this.userPrompts.findIndex(
      (elem) => elem.id === prompt.id
    );
    if (promptIndex !== -1) {
      this.userPrompts[promptIndex] = prompt;
    } else {
      this.userPrompts.push(prompt);
    }

    return this.saveUserPrompts();
  }

  async deleteUserPrompt(prompt: UserPrompt) {
    const userPrompts = this.userPrompts.filter((userPrompt) => {
      if (prompt?.id) {
        return userPrompt.id !== prompt.id;
      }
      return userPrompt.label !== prompt.label;
    });
    this.userPrompts = userPrompts;
    return this.saveUserPrompts();
  }

  async saveUserPrompts() {
    try {
      await firestore()
        .collection('/advisoryAssistantUserPrompts')
        .doc(this.rootStore.userStore.id)
        .set({ userPrompts: this.userPrompts });

      return true;
    } catch (e) {
      Sentry.captureException(e);
      throw e;
    }
  }

  appendChat(props: Message) {
    this.messages.push({
      timestamp: new Date(),
      ...props,
    });
    this.persistChat();
  }

  clearChat() {
    this.messages = [];
    this.chatId = null;
  }

  persistChat = async () => {
    if (this.chatId) {
      await this.persistExistingChat();
    } else {
      await this.persistNewChat();
    }
  };

  persistNewChat = async () => {
    try {
      const docRef = await firestore()
        .collection('/advisoryAssistantChat')
        .add(this.chat);

      this.chatId = docRef.id;
    } catch (e) {
      Sentry.captureException(e);
      throw e;
    }
  };

  persistExistingChat = async () => {
    try {
      await firestore()
        .collection('/advisoryAssistantChat')
        .doc(this.chatId)
        .update(this.chat);
    } catch (e) {
      Sentry.captureException(e);
      throw e;
    }
  };

  getDashboard = (dataset) => {
    const includeUnreconciled = dataset.includes('unreconciled');
    const includeRevenue = dataset.includes('revenue');
    const includeNetProfit = dataset.includes('netProfit');
    const includeIncomeTax = dataset.includes('incometax');
    const includeCashflow = dataset.includes('cashflow');
    const includeGstestimate = dataset.includes('gstestimate');
    const includeGstcompletion = dataset.includes('gstcompletion');
    let message = '\n Business Name';
    if (includeUnreconciled) {
      message += ', Unreconciled Items, Oldest Unreconciled Item';
    }
    if (includeRevenue) {
      message += ', Revenue Forecast, Revenue Trend';
    }
    if (includeNetProfit) {
      message += ', Net Profit Forecast, Net Profit Trend';
    }
    if (includeIncomeTax) {
      message += ', Income Tax Forecast, Income Tax Trend';
    }
    if (includeCashflow) {
      message += ', Cash Position, Cash Position Trend';
    }
    if (includeGstestimate) {
      message += ', GST Forecast, GST Trend';
    }
    if (includeGstcompletion) {
      message += ', GST  Completion, GST Due Date, GST Status';
    }
    message += '\n';

    this.rootStore.dashboardStore.businesses
      // .filter((row) => !row.loading)
      .forEach((row) => {
        message += `"${row?.businessName}", `;
        if (includeUnreconciled) {
          const unreconciled = row.data?.find((d) => d.key === 'unreconciled');
          message += `"${unreconciled?.current?.formattedValue}", "${unreconciled?.change?.rawValue}", `;
        }
        if (includeRevenue) {
          const revenue = row.data?.find((d) => d.key === 'revenue');
          message += `"${revenue?.current?.formattedValue}", "${revenue?.trendDirection} ${revenue?.change?.formattedValue}", `;
        }
        if (includeNetProfit) {
          const netProfit = row.data?.find((d) => d.key === 'netProfit');
          message += `"${netProfit?.current?.formattedValue}", "${netProfit?.trendDirection} ${netProfit?.change?.formattedValue}", `;
        }
        if (includeIncomeTax) {
          const incometax = row.data?.find((d) => d.key === 'incometax');
          message += `"${incometax?.current?.formattedValue}", "${incometax?.trendDirection} ${incometax?.change?.formattedValue}", `;
        }
        if (includeCashflow) {
          const cashflow = row.data?.find((d) => d.key === 'cashflow');
          message += `"${cashflow?.current?.formattedValue}", "${cashflow?.trendDirection} ${cashflow?.change?.formattedValue}", `;
        }
        if (includeGstestimate) {
          const gstestimate = row.data?.find((d) => d.key === 'gstestimate');
          message += `"${gstestimate?.current?.formattedValue}", "${gstestimate?.trendDirection} ${gstestimate?.change?.formattedValue}", `;
        }
        if (includeGstcompletion) {
          const gstestimate = row.data?.find((d) => d.key === 'gstestimate');
          const gstcompletion = row.data?.find(
            (d) => d.key === 'gstcompletion'
          );
          message += `"${gstcompletion?.current?.formattedValue}", "${gstcompletion?.change?.formattedValue}", "${gstestimate?.reportStatus}", `;
        }
      });
    return message;
  };

  getInsights = () => {
    const nonProfitabilityInsights = [
      'cashFlow',
      'cashFlowActual',
      'invoiceStatus',
      'gst',
      'incomeTax',
      'reconciliation',
    ];

    return this.rootStore.insightStore?.insightData
      ?.filter(
        ({ insightKey }) => !nonProfitabilityInsights.includes(insightKey)
      )
      ?.map(({ headline, periods, quarters }) => {
        let message =
          'Data not available for this profitabilty insight period.';
        let period;
        switch (this.rootStore.timePeriodStore?.periodGranularity) {
          case PeriodTypes.QUARTERLY:
            period = quarters?.find(
              (p) => p?.periodData?.name
                === this.rootStore.timePeriodStore?.profitabilityPeriodSelected
            );
            break;
          default:
            period = periods?.find(
              (p) => p?.periodData?.name
                === this.rootStore.timePeriodStore?.profitabilityPeriodSelected
            );
            break;
        }

        if (period) {
          message = period?.chartSummary?.[0]?.body;
        }
        return {
          title: headline?.title,
          message,
        };
      });
  };

  loadChatHistory = () => {
    // eslint-disable-next-line no-mixed-operators
    const businessId =
      this.page === 'insights'
        ? this.rootStore.businessStore?.selectedBusinessId
        : this.page === 'dashboard'
          ? this.rootStore.practiceStore.id
          : this.page;
    firestore()
      .collection('/advisoryAssistantChat')
      .where('businessId', '==', businessId || '')
      .get()
      .then((querySnapshot) => {
        this.messageHistory = querySnapshot.docs
          .map((doc) => doc.data())
          .filter((chat) => chat.userId === this.rootStore.userStore.id)
          .sort((a, b) => b.timestamp - a.timestamp)
          .reduce(
            (acc, obj) => acc.concat(
              obj.messages
                .sort((a, b) => b.timestamp - a.timestamp)
                .map((m) => ({
                  role: m.from,
                  content: m.message?.trim() || '',
                }))
            ),
            []
          )
          .reverse();
      });
  };

  setPage = (page: string) => {
    this.page = page;
  };
}
