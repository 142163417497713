import { CheckCircleFilled, ExclamationCircleFilled, InfoCircleFilled } from '@ant-design/icons';
import React from 'react';
import { WarningTriangle } from './icons';
import GenAI from './icons/GenAI';

interface IAiderAlertProps {
  className?: string;
  type?: 'info' | 'error' | 'success' | 'warning' | 'genai';
  ghost?: boolean;
  borderless?: boolean;
  title?: string | null;
  icon?: React.ReactNode;
  showIcon?: boolean;
  callToAction?: React.ReactNode;
  children?: React.ReactNode;
  largeIcon?: boolean;
  theme?: 'primary' | 'secondary' | 'muted';
}

const AiderAlert = (
  {
    className,
    type,
    ghost,
    borderless,
    title,
    icon,
    showIcon,
    callToAction,
    children,
    largeIcon,
    theme,
  }: IAiderAlertProps
) => {
  const defaultIcon = {
    info: <InfoCircleFilled />,
    error: <ExclamationCircleFilled />,
    success: <CheckCircleFilled />,
    warning: <WarningTriangle />,
    genai: <GenAI />
  };

  return (
    <div
      className={`
        aider-alert
        aider-alert--${type}
        ${ghost ? 'aider-alert--ghost' : ''}
        ${borderless ? 'aider-alert--borderless' : ''}
        ${largeIcon ? 'aider-alert--large-icon' : ''}
        aider-alert--theme-${theme}
        ${className}`}
      role='alert'
    >
      {showIcon
        && (
          <div className='aider-alert__icon'>
            {icon || defaultIcon[type]}
          </div>
        )}
      <div className='aider-alert__content'>
        <div className={`aider-alert__content--info ${title && !children ? 'aider-alert__content--info__title-only' : ''}`}>
          {title
            && (
              <p
                role='heading'
                aria-level={5}
                className='aider-alert__content--title'
              >
                {title}
              </p>
            )}
          {
            callToAction && callToAction
          }
        </div>
        <div className='aider-alert__content--body'>
          {children && children}
        </div>
      </div>
    </div>
  );
};

AiderAlert.defaultProps = {
  className: '',
  type: 'info',
  ghost: false,
  borderless: false,
  title: null,
  icon: null,
  showIcon: true,
  callToAction: null,
  children: null,
  largeIcon: false,
  theme: 'primary',
};

export default AiderAlert;
